.container {
    background-color: #eeeeee;
    background-image: url(/login-cover.png);
    background-position: 20px 50px;
    background-repeat: no-repeat;
}

[data-theme='dark'] .container {
    background-color: #222;
    background-image: url(/login-cover-dark.png);
}
